import React, { useEffect, useState } from "react";
import CompleteType from "../utils/CompleteType";
import BioPharma from "../assets/images/BioPharma.png";
import IndustrialBio from "../assets/images/IndustrialBio.png";
import Computational from "../assets/images/Computational.png";
import Wetlab from "../assets/images/WetLab.png";
import setup from "../assets/images/setup.png";
// import AgritechDiscovery from "../assets/images/AgritechDiscovery.png";
// import FoodBio from "../assets/images/FoodBio.png";
import { getWorkflowTags } from "../api/onboard";
import { BsPlus } from "react-icons/bs";
import { MdClose } from "react-icons/md";

const titles = [
  "1. Which best describes your industry?",
  "2. What are the 3 most relevant workflows in your industry?",
  "3. What are some techniques that you use in your workflow?",
  "4. What role(s) do you identify with most?",
];

const industryCards = [
  { title: "Bio Pharma", img: BioPharma, value: "biopharma" },
  { title: "Industrial Bio", img: IndustrialBio, value: "industrialBio" },
  // { title: "Agritech Discovery", img: AgritechDiscovery, value: "agritech" },
  // { title: "Food Bio", img: FoodBio, value: "foodbio" },
];

const roleCards = [
  {
    title: "Wet Lab",
    img: Wetlab,
    subtext: "I usually run and plan experiments in the lab",
  },
  {
    title: "Computational Bio",
    img: Computational,
    subtext: "I usually program & analyze data",
  },
  // { title: "Agritech Discovery", img: AgritechDiscovery, value: "agritech" },
  // { title: "Food Bio", img: FoodBio, value: "foodbio" },
];

const workflows = {
  biopharma: [
    "Genomics",
    "Proteomics",
    "In silico",
    "Cell Culture",
    "Imaging",
    "Molecular Biology",
    "In vitro",
    "Biospecimen",
    "Analytical",
    "High Throughput Screening",
    "Compound Synthesis",
    "Metabolomics",
    "Microbiome",
    "Bioprocessing",
    "In vivo",
  ],
  industrialBio: [
    "Fermentation",
    "Microbiology",
    "Molecular Biology",
    "Analytical",
  ],
  agritech: [
    "Fermentation",
    "Microbiology",
    "Molecular Biology",
    "Processing",
    "Preservation",
  ],
  foodbio: [
    "Fermentation",
    "Microbiology",
    "Molecular Biology",
    "Processing",
    "Preservation",
    "Nutrition Labelling",
  ],
};
function OnboardingModal({
  step,
  disableNext,
  setDisableNext,
  complete,
  setComplete,
  setSurveyData
}) {

  const [selectedIndustry, setSelectedIndustry] = useState("");
  const [selectedRole, setSelectedRole] = useState("");
  const [industryWorkflows, setIndustryWorkflows] = useState([]);
  const [selectedWorkflows, setSelectedWorkflows] = useState([]);
  const [industryTechniques, setIndustryTechniques] = useState([]);
  const [selectedTechniques, setSelectedTechniques] = useState([]);

  const gotoCallScispot = () => {
    window.location = "https://calendly.com/scispot/consultation-call-w-scispot?month=2022-12"
  }

  useEffect(() => {

    let survey = {
      selectedIndustry: selectedIndustry,
      selectedWorkflows: selectedWorkflows,
      selectedTechniques: selectedTechniques,
    }

    setSurveyData(survey)
  
  }, [selectedIndustry, selectedWorkflows, selectedTechniques])  

  useEffect(() => {
    if (
      (step === 0 && selectedIndustry === "") ||
      (step == 1 && selectedWorkflows.length == 0)
    ) {
      setDisableNext(true);
    } else {
      setDisableNext(false);
    }
    if (selectedIndustry !== "") {
      setIndustryWorkflows(workflows[selectedIndustry]);
    }
  }, [step, selectedIndustry, selectedWorkflows]);

  useEffect(() => {
    setSelectedWorkflows([]);
  }, [industryWorkflows]);

  useEffect(() => {
    if (step < 4) {
      setComplete(null);
    }
    if (step === 2) {
      let industry =
        selectedIndustry === "biopharma"
          ? "biopharma"
          : selectedIndustry === "industrialBio"
          ? "Industrial Bio"
          : selectedIndustry === "agritech"
          ? "agritech"
          : selectedIndustry === "foodbio"
          ? "food"
          : "";

      getWorkflowTags(industry, selectedWorkflows, (result) => {
        setIndustryTechniques(result?.slice(0, 40));
      });
    }
  }, [step]);

  const renderIndustryCards = () => {
    return (
      <div className="industry-cards">
        {industryCards.map((card, index) => (
          <div
            onClick={() => {
              if (selectedIndustry === card.value) {
                setSelectedIndustry("");
              } else {
                setSelectedIndustry(card.value);
              }
            }}
            style={{
              cursor: "pointer",
              display: "flex",
              flexDirection: "column",
              margin: "auto auto",
              alignItems: "center",
              justifyContent: "center",
              border:
                selectedIndustry == card.value
                  ? "2px solid #6A35FF"
                  : "2px solid #D1D5DB",
              borderRadius: "4px",
              marginBottom: "20px",
            }}
            key={index}
          >
            <img alt={card.value} src={card.img} height={200} />
            <div style={{ padding: "8px 0px" }}> {card.title}</div>
          </div>
        ))}
      </div>
    );
  };

  const renderWorkflows = () => {
    return (
      <div>
        {" "}
        <div style={{ fontSize: "14px", color: "#565656" }}>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              flexWrap: "wrap",
              gap: "8px",
              marginTop: "6px",
            }}
          >
            {selectedWorkflows.map((workflow, index) => {
              return (
                <div
                  style={{
                    cursor: "pointer",
                    display: "flex",
                    flexDirection: "row",
                    padding: "2px 4px",
                    border: "2px solid #6A35FF",
                    borderRadius: "4px",
                    backgroundColor: "#6A35FF",
                    alignItems: "center",
                    color: "white",
                  }}
                  key={index}
                  onClick={() => {
                    setSelectedWorkflows(
                      selectedWorkflows.filter((w) => w !== workflow)
                    );
                  }}
                >
                  {workflow}
                  <MdClose color={"white"} />
                </div>
              );
            })}
          </div>
          Suggested workflows according to your industry
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              flexWrap: "wrap",
              gap: "8px",
              marginTop: "6px",
            }}
          >
            {industryWorkflows
              .filter((workflow) => {
                return !selectedWorkflows.includes(workflow);
              })
              .map((workflow, index) => {
                return (
                  <div
                    style={{
                      cursor: "pointer",
                      display: "flex",
                      flexDirection: "row",
                      padding: "2px 4px",
                      border: "2px solid #565656",
                      borderRadius: "4px",
                      alignItems: "center",
                    }}
                    key={index}
                    onClick={() => {
                      if (selectedWorkflows.length < 3) {
                        setSelectedWorkflows([...selectedWorkflows, workflow]);
                      }
                    }}
                  >
                    {workflow}
                    <BsPlus color={"#565656"} />
                  </div>
                );
              })}
          </div>
        </div>
      </div>
    );
  };

  const renderTechniques = () => {
    return (
      <div>
        {" "}
        <div
          style={{
            fontSize: "14px",
            color: "#565656",
            maxHeight: "400px",
            overflowY: "scroll",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              flexWrap: "wrap",
              gap: "8px",
              marginTop: "6px",
            }}
          >
            {selectedTechniques.map((technique, index) => {
              return (
                <div
                  style={{
                    cursor: "pointer",
                    display: "flex",
                    flexDirection: "row",
                    padding: "2px 4px",
                    border: "2px solid #6A35FF",
                    borderRadius: "4px",
                    backgroundColor: "#6A35FF",
                    alignItems: "center",
                    color: "white",
                  }}
                  key={index}
                  onClick={() => {
                    setSelectedTechniques(
                      selectedTechniques.filter((w) => w !== technique)
                    );
                  }}
                >
                  {technique.technique}
                  <MdClose color={"white"} />
                </div>
              );
            })}
          </div>
          Suggested techniques according to your industry
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              flexWrap: "wrap",
              gap: "8px",
              marginTop: "6px",
            }}
          >
            {industryTechniques
              .filter((technique) => {
                return !selectedTechniques.includes(technique);
              })
              .map((technique, index) => {
                return (
                  <div
                    style={{
                      cursor: "pointer",
                      display: "flex",
                      flexDirection: "row",
                      padding: "2px 4px",
                      border: "2px solid #565656",
                      borderRadius: "4px",
                      alignItems: "center",
                    }}
                    key={index}
                    onClick={() => {
                      if (selectedTechniques.length < 3) {
                        setSelectedTechniques([
                          ...selectedTechniques,
                          technique,
                        ]);
                      }
                    }}
                  >
                    {technique.technique}
                    <BsPlus color={"#565656"} />
                  </div>
                );
              })}
          </div>
        </div>
      </div>
    );
  };

  const renderRoles = () => {
    return (
      <div className="industry-cards">
        {roleCards.map((card, index) => (
          <div
            onClick={() => {
              if (selectedIndustry === card.title) {
                setSelectedRole("");
              } else {
                setSelectedRole(card.title);
              }
            }}
            style={{
              cursor: "pointer",
              display: "flex",
              flexDirection: "column",
              margin: "auto auto",
              alignItems: "center",
              justifyContent: "center",
              border:
                selectedRole == card.title
                  ? "2px solid #6A35FF"
                  : "2px solid #D1D5DB",
              borderRadius: "4px",
              marginBottom: "20px",
            }}
            key={index}
          >
            <img alt={card.title} src={card.img} height={200} />
            <div style={{ paddingTop: "8px 6px", fontSize: "20px" }}>
              {" "}
              {card.title}
            </div>
            <div
              style={{
                textAlign: "center",
                padding: "0px 6px",
                width: "205px",
              }}
            >
              {" "}
              {card.subtext}
            </div>
          </div>
        ))}
      </div>
    );
  };

  const renderSuccess = () => {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <img src="/assets/images/finish-01.png"/>
        <div className="final-message">Congratulations! We are now setting up your account and you will be redirected once your account is ready</div>
      </div>
    );
  };

  const renderWaitlist = () => {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <img src="/assets/images/finish-02.png"/>
        <div className="final-message">Congratulations! You have been added to our waitlist. You are likely to get access in the next 24-48 hours and you will receive an email notification once your account is set up. Thank you for choosing Scispot.</div>
        <div className="got-questions-waitlist" onClick={gotoCallScispot}>Got questions? Contact us now</div>
      </div>
    );
  };

  useEffect(() => {
    if(complete == CompleteType.ACCOUNT_CREATED) {
      setTimeout(() => {
        window.location = localStorage.getItem("token") ?  "https://labsheets.scispot.io/home?token=" + localStorage.getItem("token") : "https://labsheets.scispot.io/signin"
      }, 4000)
    }
  }, [complete])

  return (
    <div className="onboard-block">
      {!complete && step < 4 && (
        <>
          <div className="title-txt">
            Let us know a bit more about your industry
          </div>
          <div style={{ fontWeight: "500", fontSize: "14px", color: "#565656" }}>
            This will help us personalize your platform
          </div>
          <div className="step-txt">
            {" "}
            {titles[step]}
          </div>
          {step === 0 && renderIndustryCards()}
          {step === 1 && renderWorkflows()}
          {step === 2 && renderTechniques()}
          {step === 3 && renderRoles()}
        </>
      )}
      { !complete && step == 4 && <img alt="" width="100%" src={setup} /> }
      {complete == CompleteType.WAITLIST ? renderWaitlist() : complete == CompleteType.ACCOUNT_CREATED ? renderSuccess() : <></>}
    </div>
  );
}

export default OnboardingModal;
