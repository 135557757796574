import React, { useEffect } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { renderConsoleWarning } from "./utils/functions";
import { Toaster } from "react-hot-toast";
import Auth from "./views/Auth";
import Onboarding from "./views/Onboarding";
import NotFound from "./views/NotFound";
import Profile from "./views/Profile";
import Workspaces from "./views/Workspaces/Workspaces";
import WorkspaceCreate from "./views/Workspaces/WorkspaceCreate";
import WorkspaceInvite from "./views/Workspaces/WorkspaceInvite";
import axios from "axios";
import { useSelector } from "react-redux";
import PrivateRoute from "./components/PrivateRoute";
import Signin from "./views/Signin";

function App(props) {

  const { customer, isLoggedIn } = useSelector((state) => state.auth);

  axios.defaults.baseURL = 
    process.env.REACT_APP_API_URL ?? "https://api.scispot.io/tryingtofixcors";

  if (isLoggedIn) {
    axios.defaults.headers.common["Scispot-Token"] = customer.token;
  }

  useEffect(() => {
    renderConsoleWarning();
  }, []);

  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route exact path="/" element={<Auth />} />
          <Route exact path="/onboarding" element={<Onboarding />} />
          <Route exact path="/signin" element={<Signin />} />
          <Route
            exact
            path="/profile"
            element={
              <PrivateRoute>
                <Profile />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path="/workspaces"
            element={
              <PrivateRoute>
                <Workspaces />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path="/workspaces/create"
            element={
              <PrivateRoute>
                <WorkspaceCreate />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path="/workspaces/:workspaceId/invite"
            element={
              <PrivateRoute>
                <WorkspaceInvite />
              </PrivateRoute>
            }
          />
          <Route path="*" element={<NotFound />} status={404} />
        </Routes>
      </BrowserRouter>
      <Toaster position="top-left" />
    </>
  );
}

export default App;
