import React, { useState } from 'react';

function CustomerList({customers, goHome}) {

    const [customersSelected, setCustomersSelected] = useState(null)

    return (
        <>
            <div className='customer-list'>
                <div className='customer-list-title'>Select your customer:</div>
                <div className='form-container-customer-list'>
                { customers && customers?.map((c, ind) => {
                    return (
                        <div onClick={() => {
                            setCustomersSelected(c)
                            goHome(c?.token)
                        }} key={`customer_list_item_${ind}`} className={`customer-list-item ${customersSelected?.customerReference == c?.customerReference ? "customer-selected" : ""}`}>
                        { c?.customerName }
                        </div>
                    )
                })}
                </div>
            </div>            
        </>    
    )

}
export default CustomerList