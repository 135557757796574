import React, {useEffect} from 'react';
import RightArrowIcon from "../../components/RightArrowIcon";
import LeftPaneImage from "../../components/LeftPaneImage";
import HorizontalLogo from "../../components/HorizontalLogo";
import TextWithHorizontalLines from "../../components/TextWithHorizontalLines";
import WorkspacesJoinedList from "../../components/WorkspacesJoinedList";
import WorkspacesPendingInviteList from "../../components/WorkspacesPendingInviteList";
import Toaster from "../../utils/Toaster";
import axios from "axios";
import {useDispatch} from "react-redux";
import {setWorkspaces} from "../../store/workspaces";
import {useNavigate} from "react-router";

function Workspaces(props) {

    const isSubmitLoading = false;

    const navigate = useNavigate();

    const dispatch = useDispatch();

    useEffect(() => {
        axios.get('workspaces').then((response) => {
            dispatch(setWorkspaces(response.data));
        }).catch((response) => {
            Toaster.error(response.data.message);
        })
    })

    return (

        <div className="auth-container">
            <LeftPaneImage/>
            <div className="form-container">
                <form>
                    <HorizontalLogo/>
                    <div className="interaction-container">
                        <WorkspacesJoinedList/>
                        <WorkspacesPendingInviteList/>
                        <TextWithHorizontalLines text="Or create a workspace"/>
                        <div className={`form-group ${isSubmitLoading && "loading"}`}>
                            <button onClick={()=>{navigate('/workspaces/create')}} type="button" className={isSubmitLoading && "loading"}>Create New Workspace <RightArrowIcon/>
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    );
}

export default Workspaces;