import toast from "react-hot-toast";
import ToastSound from "../assets/audios/pop-sound.wav";

class Toaster {
    state = {
        toastSound: new Audio(ToastSound),
    };

    playToastSound = () => {
        try {
            this.state.toastSound.play();
        } catch (e) {
            console.log(e.message)
        }
    };

    notify = (message) => {
        this.playToastSound();
        toast(message);
    };

    success = (message) => {
        this.playToastSound();
        toast.success(message);
    };

    error = (message) => {
        this.playToastSound();
        toast.error(message);
    };

    customJSX = (jsxContent) => {
        this.playToastSound();
        toast.custom(jsxContent);
    };
}

export default new Toaster();