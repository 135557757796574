import React, { useEffect } from "react";
import LeftPaneImage from "../components/LeftPaneImage";
import HorizontalLogo from "../components/HorizontalLogo";
import Register from "../components/Register";
import Login from "../components/Login";
import FormType from "../utils/FormType";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";

function Auth(props) {
  const authFormType = useSelector((state) => state.ui.pages.auth.authFormType);
  const { customer, isLoggedIn } = useSelector((state) => state.auth);
  const navigate = useNavigate();

  useEffect(() => {
    if (isLoggedIn && customer.firstName !== undefined) {
      navigate("/workspaces");
    } else if (isLoggedIn) {
      navigate("/profile");
    }
  }, [isLoggedIn]);
  useEffect(() => {
    localStorage.setItem("emailSignup", "");
  }, []);

  return (
    <div className="auth-container">
      <LeftPaneImage />
      <div className="form-container">
        <form>
          <HorizontalLogo />
          {authFormType === FormType.LOGIN ? <Login /> : <Register />}
        </form>
      </div>
    </div>
  );
}

export default Auth;
