import React from "react";
import Logo from "../assets/images/logo.svg";

export default function HorizontalLogo(props) {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "flex-row",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <img src={Logo} className="horizontal-logo" style= {{ height: props.size === "small" ? "80px" : ""}} alt="Scispot's Logo" />
      <div
        style={{
          fontSize: props.size === "small" ? "24px" : "36px",
          fontWeight: "700",
          fontFamily: "DM Sans",
          marginBottom: "6px",
        }}
      >
        pico
      </div>
    </div>
  );
}
