import axios from "axios";
import { v4 as uuidv4 } from "uuid";
import { execute, onboardUser, tags } from "../base-crud";

const callApi = (api, request, callback = null) => {
  if (callback) {
    execute(api, request, callback);
  } else {
    return execute(api, request);
  }
};

export const getWorkflowTags = (industry, workflows = [], callback = null) => {
  let payload = {
    action: "FILTER",
    industry: industry,
    workflows: workflows,
  };

  /* Eg: 
    Payload:
        {
            "action": "filter",
            "industry": "Biopharma",
            "workflows": ["Genomics", "In silico"]
        }
    Response:
        List of all techniques that belong to these workflows
        [
            {
                "Id": "4ab4d273-62ae-4575-bb2b-b2f5aae46142",
                "type": "onboard-tag",
                "index": "global",
                "industry": "Biopharma",
                "workflow": "Genomics",
                "technique": "Agarose Gel Electrophoresis"
            },
            {
                "Id": "8c30f308-1526-4d4b-8975-d27c2d922094",
                "type": "onboard-tag",
                "index": "global",
                "industry": "Biopharma",
                "workflow": "Genomics",
                "technique": "Automated Sequencing"
            },
       ]
    */

  callApi(tags, payload, callback);
};

export const onboardNewUser = (
  email,
  userName,
  customerName,
  survey,
  thirdPartyToken,
  callback = null
) => {
  let payload = {
    thirdPartyToken: thirdPartyToken,
    user: {
      email: email,
      name: userName,
    },
    customer: {
      name: customerName,
    },
    survey: survey, // Can have any attributes, is not specific
  };

  /* Eg:
    Response: 
        {
            "token": "login_token"
        }
    Using the token u can direct user to url labsheets.scispot.io/home?token=login_token to signin
    */

  callApi(onboardUser, payload, callback);
};

export const logEvent = (event_name, event_message) => {

    if(typeof window != "object") return

    let email = localStorage.getItem("emailSignup")
    let domain = email.substring(email.indexOf("@") + 1)
    let userName = localStorage.getItem("userName")
    let customerName = localStorage.getItem("customerName")

    let now = new Date();
    let logObj = {
      action: event_name,
      uuid: uuidv4(),
      message: event_message,
      timestamp: now.toISOString(),
      user: {
        user: email,
        userName: userName,
        customer: domain,
        customerName: customerName,
        token: null,
        session: null,
      },
      client: {
        uri: window?.location?.hostname,
      },
    };

    axios.post(`https://api.scispot.io/tryingtofixcors/log/add`, logObj);

}
