import React, { useRef } from "react";
import FormHeadingContainer from "../components/FormHeadingContainer";
import RightArrowIcon from "../components/RightArrowIcon";
import LeftPaneImage from "../components/LeftPaneImage";
import HorizontalLogo from "../components/HorizontalLogo";
import { useNavigate } from "react-router";
import Toaster from "../utils/Toaster";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { setCustomer } from "../store/auth";

function Profile(props) {
  const isSubmitLoading = false;

  const firstNameRef = useRef();
  const lastNameRef = useRef();
  const roleRef = useRef();
  const sourceRef = useRef();

  const navigate = useNavigate();

  const dispatch = useDispatch();

  const { customer } = useSelector((state) => state.auth);

  const handleSubmit = (e) => {
    e.preventDefault();
    roleRef.current.setCustomValidity("Please add your role");
    sourceRef.current.setCustomValidity(
      "We are curious about this. Please tell us! :)"
    );

    if (
      roleRef.current.options[roleRef.current.selectedIndex].attributes
        .value !== undefined
    ) {
      roleRef.current.setCustomValidity("");
    }

    if (
      sourceRef.current.options[sourceRef.current.selectedIndex].attributes
        .value !== undefined
    ) {
      sourceRef.current.setCustomValidity("");
    }

    if (
      firstNameRef.current.reportValidity() &&
      lastNameRef.current.reportValidity() &&
      roleRef.current.reportValidity() &&
      sourceRef.current.reportValidity()
    ) {
      axios
        .post("/customers/profile", {
          firstName: firstNameRef.current.value,
          lastName: lastNameRef.current.value,
          role: roleRef.current.value,
          source: sourceRef.current.value,
        })
        .then(() => {
          dispatch(
            setCustomer({
              ...customer,
              firstName: firstNameRef.current.value,
              lastName: lastNameRef.current.value,
              role: roleRef.current.value,
              source: sourceRef.current.value,
            })
          );
          navigate("/workspaces");
        })
        .catch((response) => {
          Toaster.error(response.data.message);
        });
    }
  };

  return (
    <div className="auth-container">
      <LeftPaneImage />
      <div className="form-container">
        <form>
          <HorizontalLogo />
          <FormHeadingContainer
            title="Profile"
            subtitle="Let's customize your experience"
          />
          <div className="interaction-container">
            <div className="form-group many">
              <input
                ref={firstNameRef}
                type="text"
                name="first_name"
                placeholder="First Name"
                required={true}
              />
              <input
                ref={lastNameRef}
                type="text"
                name="last_name"
                placeholder="Last Name"
                required={true}
              />
            </div>
            <div className="form-group">
              <select ref={roleRef} required={true}>
                <option selected disabled>
                  Role/Position
                </option>
                <option value="Engineer">Engineer</option>
                <option value="Business">Business</option>
                <option value="Administration">Administration</option>
                <option value="Scientist">Scientist</option>
              </select>
            </div>
            <div className="form-group">
              <select ref={sourceRef} required={true}>
                <option selected disabled>
                  Where did you hear about Scispot?
                </option>
                <option value="LinkedIn">LinkedIn</option>
                <option value="Google Search">Google Search</option>
                <option value="Blog/Article/News">Blog/Article/News</option>
                <option value="Friend">Friend</option>
                <option value="Eavesdropping">Eavesdropping</option>
                <option value="God appeared into my dreams">
                  God appeared into my dreams
                </option>
              </select>
            </div>
            <div className={`form-group ${isSubmitLoading && "loading"}`}>
              <button
                onClick={handleSubmit}
                type="button"
                className={isSubmitLoading && "loading"}
              >
                Continue <RightArrowIcon />
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}

export default Profile;
