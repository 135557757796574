import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { handleMagicLinkEmailAuth } from "../utils/MagicLinkHelper";
import PropTypes from "prop-types";
import Toaster from "../utils/Toaster";
import FormType from "../utils/FormType";


function MagicAuth(props) {
  const emailRef = useRef();
  const nameRef = useRef();
  const organizationRef = useRef();
  const dispatch = useDispatch();

  const { buttonText, buttonType, headerText, blacklistText } = props;

  const [verifying, setVerifying] = useState(false)
  const [blacklistedEmail, setBlacklistedEmail] = useState(false)
  const [blacklist, setBlacklist] = useState([
    "hotmail.com",
    "yahoo.com",
  ])

  const checkBlackList = (resp) => {
    setBlacklist(resp)
  }

  const checkEmail = () => {
    try {
      const email = emailRef?.current?.value?.toLowerCase() ?? ""
      if((email?.length ?? 0) > 0) {
        const domain = email.split("@")[email.split("@")?.length - 1]
        if(blacklist.includes(domain)) setBlacklistedEmail(true)
        else setBlacklistedEmail(false)
      }
    } catch(e) {
      setBlacklistedEmail(true)
    }
  }

  const magicLinkClick = () => {

    if(buttonType == FormType.REGISTER) {
      if(props.signedRef.current.checked == false) {
        Toaster.error("To create your account, you need to agree to our terms and conditions")
        return
      }
    }

    if(blacklistedEmail) return

    const email = emailRef.current.value
    if (nameRef?.current?.value) localStorage.setItem("userName", nameRef.current.value)
    if (organizationRef?.current?.value) localStorage.setItem("customerName", organizationRef.current.value)
      
    setVerifying(true)
    handleMagicLinkEmailAuth(email, buttonType, dispatch, props.getSessionData, props.setRegisterEmail, setVerifying)

  }

  useEffect(() => {
    checkBlackList(blacklist)
  }, [])
  
  return (
    <React.Fragment>
      {headerText && (
        <div className="form-group">
          <div
            style={{
              textAlign: "left",
              fontFamily: "DM Sans",
              fontWeight: "500",
              marginBottom: "6px",
            }}
          >
            {"Enter your name"}
          </div>
          <input
            ref={nameRef}
            type="text"
            name="text"
            placeholder="Enter your name"
            required
          />
        </div>
      )}
      <div className="form-group">
        {headerText && (
          <div
            style={{
              textAlign: "left",
              fontFamily: "DM Sans",
              fontWeight: "500",
              marginBottom: "6px",
            }}
          >
            {headerText}
          </div>
        )}
        <input
          ref={emailRef}
          type="email"
          name="email"
          placeholder="example@workspace.com"
          onBlur={checkEmail}
          required
        />
        { blacklistedEmail ? <>
          <div
            style={{
              fontSize: "14px",
              textAlign: "left",
              marginTop: "6px",
              color: "red"
            }}
          >
            {blacklistText}
          </div>
        </> : <></>}
      </div>
      {headerText && (
        <div className="form-group">
          <div
            style={{
              textAlign: "left",
              fontFamily: "DM Sans",
              fontWeight: "500",
              marginBottom: "6px",
            }}
          >
            {"Enter your organization name"}
          </div>
          <input
            ref={organizationRef}
            type="text"
            name="text"
            placeholder="Organization name"
            required
          />
        </div>
      )}
      <div className={`form-group`}>
        <button type="button" onClick={magicLinkClick} disabled={verifying}>
          {verifying ? headerText ? "Verifying..." : "Signing in..." : buttonText}
        </button>
      </div>
    </React.Fragment>
  );
}

MagicAuth.propTypes = {
  buttonText: PropTypes.string.isRequired,
  buttonType: PropTypes.string,
};

export default MagicAuth;
