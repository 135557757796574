export const getLocalStorageCustomer = () => {
    const customer = localStorage.getItem("customer")
    if(customer && customer != "undefined") return JSON.parse(localStorage.getItem("customer"))
    else return null
}

export const setLocalStorageCustomer = (customerData) => {
    localStorage.setItem("customer", JSON.stringify(customerData));
};

export const deleteLocalStorageCustomer = () => {
    localStorage.removeItem("customer");
};

export const getLocalStorageCustomerToken = () => {
    return getLocalStorageCustomer()?.token ?? null;
};

export const isLocalStorageCustomerLoggedIn = () => {
    const customer = getLocalStorageCustomer();
    return !!(customer && customer.token);
};

export const getLocalStorageAuthHeader = () => {
    const customer = getLocalStorageCustomer();
    if (customer && customer.token) {
        return {
            "Scispot-Token": customer.token,
        };
    }
    return {};
};