import React from 'react';
import {useDispatch} from "react-redux";
import {logoutCustomer} from "../store/auth";

function LogoutButton(props) {

    const dispatch = useDispatch();

    const logoutUser = () => {
        dispatch(logoutCustomer());
    }

    return (
        <span className="logout" onClick={logoutUser}>Logout</span>
    );
}

export default LogoutButton;