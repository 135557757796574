import React, { useEffect } from "react";
import LeftPaneImage from "../components/LeftPaneImage";
import HorizontalLogo from "../components/HorizontalLogo";

function Signin(props) {
  useEffect(() => {
    // if you want to show the page
    // setTimeout(() => {
    //   window.location.replace("https://labsheets.scispot.io/signin");
    // }, 2000);
    window.location.replace("https://labsheets.scispot.io/signin");

  }, []);

  return (
    <div className="auth-container">
      <LeftPaneImage />
      <div className="form-container">
        <form>
          <HorizontalLogo />
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              width: "100%",
              marginTop: "64px",
              fontSize: "24px",
              justifyContent: "center",
            }}
          >
            Hang tight while we open sign in...
          </div>
        </form>
      </div>
    </div>
  );
}

export default Signin;
