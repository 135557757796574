import { Magic } from "magic-sdk";
import { OAuthExtension } from "@magic-ext/oauth";
import Toaster from "./Toaster";
import axios from "axios";
import FormType from "./FormType";

const magic = new Magic("pk_live_14115DCA4E8AD086", {
  extensions: [new OAuthExtension()],
});

const handleMagicLinkEmailAuth = (
  email,
  buttonType,
  dispatch,
  getSessionData,
  setRegisterEmail,
  setVerifying
) => {
  magic.auth
    .loginWithMagicLink({
      email: email,
    })
    .then((token) => {
      if (buttonType == FormType.LOGIN) {
        let url = "/scispot-auth";
        if (buttonType == FormType.LOGIN) {
          url = "/scispot-auth";
        }

        axios
          .post(url, {
            token: token,
            email: email,
          })
          .then((response) => {
            setVerifying(false);
            console.log("Failed 2");
            if (response?.data?.token) {
              getSessionData(response?.data?.token);
            } else {
              Toaster.error(response.data.message ?? "Something went wrong");
            }
          })
          .catch((response) => {
            setVerifying(false);
            console.log("Failed");
            Toaster.error(response.data.message ?? "Something went wrong");
          });
      } else if (buttonType == FormType.REGISTER) {
        setRegisterEmail(email);
      }
    })
    .catch(() => {
      setVerifying(false);
      console.log("Failed");
      Toaster.error("Magic login failed. Try again!");
    });
};

export { handleMagicLinkEmailAuth };
