import axios from "axios";

const host =
  typeof window == "object" &&
  (window.location.hostname == "develop.account.scispot.io" ||
    window.location.hostname == "localhost")
    ? "https://8i85gq7ftj.execute-api.us-east-1.amazonaws.com/v1"
    : "https://api.scispot.io/tryingtofixcors";

// const localHost = "http://localhost:8080"

export const tags = axios.create({
  baseURL: `${host}/onboard/tags`,
});

export const onboardUser = axios.create({
  baseURL: `${host}/scispot-onboard-user`,
});

export const execute = async (api, request, callback = null) => {
  try {
    if (callback) {
      api
        .post("", request)
        .then((response) => {
          callback(response.data);
        })
        .catch((error) => {
          //toast.error("Something went wrong");
          console.error("ops! an error has ocurred" + error, [api, request]);
          console.error("ERROR request", request);
          console.error("ERROR api", api);
          return {
            error: error,
          };
        });
    } else {
      return await api
        .post("", request)
        .then((response) => {
          return response.data;
        })
        .catch((error) => {
          console.error("ops! an error has ocurred" + error);
          console.error("ERROR request", request);
          console.error("ERROR api", api);

          return {
            error: error,
          };
        });
    }
  } catch (error) {
    return {
      error: error,
    };
  }
};
