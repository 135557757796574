import React from "react";

function LeftPaneImage(props) {
  // const {leftPaneImageURL} = useLeftPaneImageHook();

  const getContainerContent = () => {
    // if (leftPaneImageURL === null) {
    //     return <div className="loading"/>
    // }
    return (
      <img
        style={{ objectFit: "cover" }}
        src={"/assets/images/pico_landing_image.png"}
        alt="Scispot Pico Monkey Logo"
      />
    );
  };

  return (
    <div className="left-pane-image-container">{getContainerContent()}</div>
  );
}

export default React.memo(LeftPaneImage);
