import React from "react";
import LeftPaneImage from "../components/LeftPaneImage";
import OnboardingContainer from "../components/OnboardingContainer";

function Onboarding(props) {
  return (
    <div
      className="auth-container"
      style={{
        backgroundImage: `url(/assets/images/test.png)`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
      }}
    >
      <LeftPaneImage />
      <div className="onboarding-container">
        <OnboardingContainer />
      </div>
    </div>
  );
}

export default Onboarding;
