import React, {useEffect, useState} from 'react';
import WorkspaceCard from "./WorkspaceCard";
import {useSelector} from "react-redux";
import NoWorkspacesInfoCard from "./NoWorkspacesInfoCard";

function WorkspacesPendingInviteList(props) {

    const {workspaces} = useSelector((state) => state.workspaces);
    const [workspaceCards, setWorkspaceCards] = useState(<></>);

    useEffect(() => {
        const pendingWorkspaceCards = [];

        workspaces.forEach((workspace) => {
            if(!workspace.has_joined){
                pendingWorkspaceCards.push(
                    <WorkspaceCard
                        logoUrl={`https://ui-avatars.com/api/?name=${workspace.name.replace(/ /g,"+")}`}
                        buttonText="Open"
                        key={workspace.id}
                        buttonClickHandler={()=>{}}
                        workspaceName={workspace.name}
                        workspaceMembersCount={workspace.membersCount}
                    />)
            }
        })

        setWorkspaceCards(pendingWorkspaceCards);

    }, [workspaces]);

    return (
        <div className="workspace-list">
            <div className="workspace-list__heading">Pending Invites</div>
            <div className="workspace-list__cards">
                {workspaceCards.length ? workspaceCards : <NoWorkspacesInfoCard message="You don&apos;t have any pending invites."/>}
            </div>
        </div>
    );
}

export default WorkspacesPendingInviteList;