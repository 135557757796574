import React from "react";

function TextWithHorizontalLines(props) {

    const {text} = props;

    return (
        <div className="text-with-horizontal-line"><span>{text}</span></div>
    );
}

export default TextWithHorizontalLines;