import React from 'react';
import {useSelector} from "react-redux";
import {Navigate} from "react-router";
import LogoutButton from "./LogoutButton";

function PrivateRoute({children}) {

    const {isLoggedIn} = useSelector((state) => state.auth);

    if(!isLoggedIn){
        return <Navigate to="/" />
    }

    return <React.Fragment>
        {children}
        <LogoutButton/>
    </React.Fragment>;
}

export default PrivateRoute;