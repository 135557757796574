import React, { useRef } from "react";
import FormHeadingContainer from "./FormHeadingContainer";
import FormType from "../utils/FormType";
import TextWithHorizontalLines from "./TextWithHorizontalLines";
import GoogleAuthButton from "./GoogleAuthButton";
import MagicAuth from "./MagicAuth";
import { useNavigate } from "react-router";
// import { useDispatch } from "react-redux";
// import { setAuthFormType } from "../store/ui";

function Register(props) {
  //comments are for showing the login flow

  // const dispatch = useDispatch();
  // const showLoginForm = () => {
  //   dispatch(setAuthFormType(FormType.LOGIN));
  // };
  const navigate = useNavigate();
  const signedRef = useRef();

  const setRegisterEmail = (email) => {
    localStorage.setItem("emailSignup", email);
    navigate("/onboarding");
  };

  return (
    <React.Fragment>
      <FormHeadingContainer title="Create an account" />
      <div className="interaction-container">
        <div
          style={{
            width: "100%",
            display: "flex",
            textAlign: "left",
            flexFlow: "row",
            gap: "10px",
          }}
        >
          <input type={"checkbox"} ref={signedRef} />
          <span>
            Accept the{" "}
            <a
              href="https://www.scispot.com/legal/terms-conditions"
              rel="noreferrer"
              target={"_blank"}
              style={{ textDecoration: "underline" }}
            >
              terms and conditions
            </a>
          </span>
        </div>
        <div className="form-group">
          <GoogleAuthButton
            buttonText="Sign up with Google"
            setRegisterEmail={setRegisterEmail}
            buttonType={FormType.REGISTER}
            signedRef={signedRef}
          />
        </div>
        <TextWithHorizontalLines text="or" />
        <MagicAuth
          headerText="Enter your business/institute email"
          buttonText="Verify my email"
          blacklistText="please provide your business/institute email to gain priority access"
          buttonType={FormType.REGISTER}
          register={true}
          setRegisterEmail={setRegisterEmail}
          signedRef={signedRef}
        />
        <div className="form-type-switcher">
          Already have a Scispot account?{" "}
          {/* <span onClick={showLoginForm}>Sign In</span> */}
          <span
            onClick={() => {
              window.location.replace("https://labsheets.scispot.io/signin");
            }}
          >
            Sign In
          </span>
        </div>
      </div>
    </React.Fragment>
  );
}

export default Register;
