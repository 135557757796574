import React from 'react';
import PropTypes from "prop-types";
import GoogleLogin from "react-google-login";
import GoogleIcon from "./GoogleIcon";
import Toaster from "../utils/Toaster";
import axios from "axios";
import FormType from "../utils/FormType";

function GoogleAuthButton(props) {

    const {buttonText} = props;
    const buttonType = FormType[props.buttonType] ?? FormType.LOGIN

    const handleGoogleSignInSuccess = (googleLoginResponse) => {

        if(buttonType == FormType.LOGIN) {

            let url = "/scispot-auth"
            if(buttonType == FormType.LOGIN) {
                url = "/scispot-auth"
            }
    
            axios
            .post(url, {
                token: googleLoginResponse.accessToken,
                email: googleLoginResponse.profileObj.email
            })
            .then((response) => {
                    if(response?.data?.token) {
                        props.getSessionData(response?.data?.token)
                    }
                    else {
                        console.log("error response", response)
                        Toaster.error("Something went wrong")
                    } 
            })
            .catch((response) => {
                console.log("error response", response)
                Toaster.error("Something went wrong")
            })
            
        } else if(buttonType == FormType.REGISTER) {
            props.setRegisterEmail(googleLoginResponse.profileObj.email)
        }

    }

    const handleGoogleSignInFailure = () => {
        Toaster.error("Google Sign-in Failed, try again!")
    }

    return (
        <GoogleLogin
            render={renderProps => (
                <div
                    className="google-signup-button"
                    onClick={(e) => {
                        if(buttonType == FormType.REGISTER) {
                            if(props.signedRef.current.checked == false) {
                                Toaster.error("To create your account, you need to agree to our terms and conditions")
                            } else {
                                renderProps.onClick(e)
                            }
                        } else {
                            renderProps.onClick(e)
                        }
                    }}                    
                >
                    <GoogleIcon/>
                    <span>{buttonText}</span>
                </div>
            )}
            clientId="519197920087-qu8rq2md378u6m50v2e604gg0gceiico.apps.googleusercontent.com"
            isSignedIn={false}
            onSuccess={handleGoogleSignInSuccess}
            onFailure={handleGoogleSignInFailure}
            buttonText={buttonText}
            cookiePolicy={'single_host_origin'}            
        />
    );
}

GoogleAuthButton.propTypes = {
    buttonText : PropTypes.string.isRequired,
    buttonType: PropTypes.string
}

export default GoogleAuthButton;