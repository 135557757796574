import React, { useState } from "react";
import TextWithHorizontalLines from "./TextWithHorizontalLines";
import FormType from "../utils/FormType";
// import {useNavigate} from "react-router";
import {useDispatch} from "react-redux";
import {setAuthFormType} from "../store/ui";
import GoogleAuthButton from "./GoogleAuthButton";
import MagicAuth from "./MagicAuth";
import Toaster from "../utils/Toaster";
import axios from "axios";
import CustomerList from "./CustomerList";
import { logEvent } from "../api/onboard";

function Login(props) {

    // const {isAuthFormPaused} = useSelector((state) => state.ui.pages.auth.isAuthFormPaused);
    const dispatch = useDispatch();
    const [customers, setCustomers] = useState(null)
    const [showCustomerList, setShowCustomerList] = useState(null)

    const getSessionData = (token) => {

        axios
            .post("/get-current-session-data", {
                token: token
            })
            .then((response) => {
                let userCustomerList = JSON.parse(response?.data?.userCustomerList)
                if(userCustomerList && userCustomerList.length > 1) {
                    setCustomers(userCustomerList)
                    setShowCustomerList(true)
                } else {
                    goHome(token)
                }
            })
            .catch((response) => {
                console.log("error response", response)
                Toaster.error("Something went wrong");
            })

    }

    const goHome = (token) => {
        window.location = `${process.env.REACT_APP_LABSHEETS_URL ?? "https://newdev.scispot.io"}/dashboard?token=${token}`
    }

    const showRegisterForm = () => {
        logEvent("onboarding-start", "Starting onboarding")
        dispatch(setAuthFormType(FormType.REGISTER));
    }

    return (
        <React.Fragment>
        <div className="heading-container">
            <h2>{FormType.LOGIN.toLowerCase()}</h2>
            <p>Welcome to Scispot</p>
        </div>
        <div className="interaction-container">
            { showCustomerList == true ? 
            <>
                <CustomerList customers={customers} goHome={goHome} />
            </> : 
            <>
                <div className="form-group">
                    <GoogleAuthButton buttonText="Sign in with Google" getSessionData={getSessionData} />
                </div>
                <TextWithHorizontalLines text="Or continue with"/>
                <MagicAuth buttonText="Sign in with Magic Link" buttonType={FormType.LOGIN} getSessionData={getSessionData} />
                <div className="form-type-switcher">
                Don&apos;t have a Scispot account? <span onClick={showRegisterForm}>Sign Up</span>
                </div>
            </>
            }
        </div>
            </React.Fragment>
    );
}

export default Login;