import React, {useRef} from 'react';
import LeftPaneImage from "../../components/LeftPaneImage";
import HorizontalLogo from "../../components/HorizontalLogo";
import RightArrowIcon from "../../components/RightArrowIcon";
import axios from "axios";
import Toaster from "../../utils/Toaster";
import {useNavigate} from "react-router";
import FormHeadingContainer from "../../components/FormHeadingContainer";

function WorkspaceCreate(props) {

    const workspaceNameRef = useRef();

    const navigate = useNavigate();

    const createWorkspace = () => {
        if(workspaceNameRef.current.reportValidity()){
            axios.post("/workspaces/create", {
                name: workspaceNameRef.current.value
            }).then((response) => {
                Toaster.success(response.data.message)
                navigate("/workspaces");
            }).catch((response) => {
                Toaster.error(response.data.message);
            })
        }
    }

    return (
        <div className="auth-container">
            <LeftPaneImage/>
            <div className="form-container">
                <form>
                    <HorizontalLogo/>
                    <FormHeadingContainer title="Create Workspaces" subtitle="Let's get you your own space"/>
                    <div className="interaction-container">
                        <div className="form-group">
                            <input ref={workspaceNameRef} type="text" placeholder="Workspace Name" required/>
                        </div>
                        <div className={`form-group`}>
                            <button onClick={createWorkspace} type="button">Create Workspace <RightArrowIcon/>
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    );
}

export default WorkspaceCreate;