import {createSlice} from "@reduxjs/toolkit";
import FormType from "../utils/FormType";

const uiSlice = createSlice({
    name: "ui",
    initialState: {
        hasUserInteracted: false,
        pages: {
            auth: {
                isAuthFormPaused: false,
                authFormType: FormType.REGISTER
            }
        }
    },
    reducers: {
        setHasUserInteracted: (state, hasUserInteracted) => {
            state.hasUserInteracted = !!(hasUserInteracted.payload);
        },
        setIsAuthFormPaused: (state, isAuthFormPaused) => {
            state.pages.isAuthFormPaused = !!(isAuthFormPaused.payload)
        },
        setAuthFormType: (state, authFormType) => {

            const newFormType = FormType[authFormType.payload] === undefined
                ? FormType.LOGIN : FormType[authFormType.payload];
            
            state.pages.auth.authFormType = newFormType;


        }},
});

export const {
    setHasUserInteracted,
    setAuthFormType,
    setIsAuthFormPaused
} = uiSlice.actions;
export default uiSlice.reducer;