import React from "react";

function FormHeadingContainer(props) {

    const {title, subtitle} = props;

    return (
        <div className="heading-container">
            <h2>{title}</h2>
            <p>{subtitle}</p>
        </div>
    );
}

export default FormHeadingContainer;