import React from 'react';
import PropTypes from "prop-types";

function WorkspaceCard(props) {

    const {
        logoUrl,
        workspaceMembersCount,
        workspaceName,
        buttonClickHandler,
        buttonText
    } = props;

    return (
        <div className="workspace__card">
            <div className="workspace__information">
                <img className="workspace__avatar" src={logoUrl}/>
                <div className="workspace__text">
                    <div className="workspace__name">
                        {workspaceName}
                    </div>
                    <div className="workspace__meta">
                        {workspaceMembersCount} members
                    </div>
                </div>
            </div>
            <div className="workspace__button" onClick={buttonClickHandler}>
                {buttonText}
            </div>
        </div>
    );
}

WorkspaceCard.propTypes = {
    logoUrl: PropTypes.string.isRequired,
    buttonText: PropTypes.string.isRequired,
    buttonClickHandler: PropTypes.func.isRequired,
    workspaceName: PropTypes.string.isRequired,
    workspaceMembersCount: PropTypes.number.isRequired
};

export default WorkspaceCard;