import {createSlice} from "@reduxjs/toolkit";
import * as CustomerLocalStorageHelper from "../utils/CustomerLocalStorageHelper";


const authSlice = createSlice({
    name: "auth",
    initialState: {
        isLoggedIn: CustomerLocalStorageHelper.isLocalStorageCustomerLoggedIn(),
        customer: CustomerLocalStorageHelper.getLocalStorageCustomer() ?? {},
    },
    reducers: {
        setCustomer: (state, action) => {
            state.isLoggedIn = true;
            state.customer = action.payload;
            CustomerLocalStorageHelper.setLocalStorageCustomer(action.payload);
        },
        logoutCustomer: (state) => {
            state.isLoggedIn = false;
            state.customer = {};
            CustomerLocalStorageHelper.deleteLocalStorageCustomer();
        },
    },
});

export const {setCustomer, logoutCustomer} = authSlice.actions;
export default authSlice.reducer;