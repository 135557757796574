import {createSlice} from "@reduxjs/toolkit";

const workspacesSlice = createSlice({
    name: "workspaces",
    initialState: {
        workspaces: [],
    },
    reducers: {
        setWorkspaces: (state, action) => {
            state.workspaces = action.payload;
        }
    },
});

export const {setWorkspaces} = workspacesSlice.actions;
export default workspacesSlice.reducer;