import React from 'react';
import PropTypes from "prop-types";

function NoWorkspacesInfoCard(props) {
    return (
        <div className="no-workspace-info-message">{props.message}</div>
    );
}

NoWorkspacesInfoCard.propTypes = {
    message : PropTypes.string.isRequired
}

export default NoWorkspacesInfoCard;