import React, {useEffect, useState} from 'react';
import WorkspaceCard from "./WorkspaceCard";
import {useSelector} from "react-redux";
import NoWorkspacesInfoCard from "./NoWorkspacesInfoCard";

function WorkspacesJoinedList(props) {

    const {workspaces} = useSelector((state) => state.workspaces);
    const [workspaceCards, setWorkspaceCards] = useState(<></>);

    useEffect(() => {
        const joinedWorkspaceCards = [];

        workspaces.forEach((workspace) => {
            if(workspace.has_joined){
                joinedWorkspaceCards.push(
                    <WorkspaceCard
                    logoUrl={`https://ui-avatars.com/api/?name=${workspace.name.replace(/ /g,"+")}`}
                    buttonText="Open"
                    key={workspace.id}
                    buttonClickHandler={()=>{}}
                    workspaceName={workspace.name}
                    workspaceMembersCount={workspace.membersCount}
                />)
            }
        })

        setWorkspaceCards(joinedWorkspaceCards);

    }, [workspaces]);




    return (
        <div className="workspace-list">
            <div className="workspace-list__heading">Your Workspaces</div>
            <div className="workspace-list__cards">
                {workspaceCards.length ? workspaceCards : <NoWorkspacesInfoCard message="You haven&apos;t joined any workspaces!"/>}
            </div>
        </div>
    );
}

export default WorkspacesJoinedList;